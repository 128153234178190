<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvExhibitionTeaser></OvExhibitionTeaser>

	2020-11-23	init

-->

<template>
	<div class="OvExhibitionTeaser" :class="elmClasses">
		<div class="OvExhibitionTeaser__inner" v-if="exhibitions">
			<VueperSlides class="vueperSlides"
				:autoplay="false"
				XXXspeed="3000"
				:infinite="true"
				:disableArrowsOnEdges="true"
				XXXtransitionSpeed="500"
				:touchable="false"
				:slideRatio="slideRatio"
				:draggingDistance="70"
				:arrows="false"
				:arrowsOutside="true"
				:bullets="true"
				:bulletsOutside="false"
			>
				<VueperSlide class="" v-for="(exhibition, i) in exhibitions" :key="i">
					<div slot="slideContent">
						<router-link
							class="OvExhibitionTeaser__exhibition font font--bold"
							:class="exhibitionElmClasses"
							v-if="exhibition"
							:to="{ name: 'OvExhibitionView', params: { slug : exhibition.slug } }">
							<div
								class="OvExhibitionTeaser__exhibitionTitleWrapper">
								<span class="OvExhibitionTeaser__exhibitionTitle lineCrop " v-html="app.getPostTitle( exhibition )"></span>
								<span class="OvExhibitionTeaser__exhibitionLabelWrapper">
									<OvLabel2 class="OvExhibitionTeaser__exhibitionLabel font"
									 	:class="labelElmClasses"
										:text="app.getPostLabelText( exhibition )"
										:verticalAlign="'top'"
									></OvLabel2>
								</span>
							</div>
							<div class="OvExhibitionTeaser__exhibitionImageWrapper">
								<MhImage
									:imageObject="_.get( exhibition, 'acf.teaserImage', false )"
									mode="contain"
								></MhImage>
							</div>
							<OvDate
								class="OvExhibitionTeaser__exhibitionDate"
								:startDate="exhibition.acf.startDate"
								:endDate="exhibition.acf.endDate">
								<template slot="append" v-if="_.get( exhibition, 'acf.isExtended', false )">
									<span class="exhibitionExtendedLabel">
										<span
											class="exhibitionExtendedLabel__text font"
											:class="layout === 'aside' ? 'font--sizeMini' : 'font--sizeMini'"
											v-html="$t('text.extendedExhibition')"
										></span>
									</span>
								</template>
							</OvDate>
						</router-link>
					</div>
				</VueperSlide>
			</VueperSlides>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import OvDate from '@/components/OvDate.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'OvExhibitionTeaser',
		components: {
			VueperSlides,
			VueperSlide,
			OvLabel2,
			OvDate,
			MhImage,
		},
		mixins: [],
		props: {
			exhibitions: {
				type: [Array, Boolean],
				default: false,
			},
			layout: {
				type: [String, Boolean],
				default: 'default', // default | aside (weiter Ausstellungen auf der startseite)
			},
			XXXlabelFontSize: {
				type: [String, Boolean],
				default: 'default',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '__layout-' + this.layout)

				return classes
			},
			slideRatio(){
				//return 0.73 // 0.73
				//return 0.73 // 0.73
				//return 0.83 // 0.73
				//return 0.73
				return ['xl', 'lg', 'dt'].includes( this.$mq ) ? 0.73 : 1
			},
			exhibitionElmClasses(){
				const classes = []
				//let fontClass = ['xl', 'lg', 'dt'].includes( this.$mq ) ? 'big' : 'default'

				if( this.layout == 'default' ){
					//classes.push('font--sizeBig') // + this._.upperFirst( titleFontSize ))
				}
				if( this.layout == 'aside' ){
					//classes.push('font--sizeSmall') // + this._.upperFirst( titleFontSize ))
				}

				//classes.push('layout layout--' + this.layout)

				return classes
			},
			labelElmClasses(){
				let classes = []

				//classes.push('font--sizeBig') // + this._.upperFirst( titleFontSize ))

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	XX.OvExhibitionTeaser { // debug
		&__exhibition {
			&TitleWrapper {
				background-color: fade(yellow, 35);
			}
			&LabelWrapper {
				background-color: fade( green, 20 );
			}
			&ImageWrapper {
				background-color: fade( red, 20 );
			}
			&Date {
				background-color: fade( blue, 20 );
			}
		}
	}
	.OvExhibitionTeaser {
		position: relative;
		color: inherit;

		&__exhibition {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			padding: 3.5% 3.25%;
			display: flex;
			flex-direction: column;
			color: inherit;

			&TitleWrapper {
				display: flex;
			}
			&Title {
				flex-grow: 1;
				padding-right: 0.5em;
			}
			&LabelWrapper {}
			&Label {
				transform-origin: top right;
				transform: scale(0.65);
			}
			&ImageWrapper {
				//background-color: fade( red, 50 );
				display: flex;
				flex-grow: 1;
				padding: 0.75em;

				.mhImage { flex-grow: 1; }
			}
			&Date {
				// 2021-04-01  warum war das drin?
				// ich habe es deaktiviert, damit das "verlängert bis"
				// label reinpasst
				//white-space: pre;
			}
		}

		.vueperSlides__bullets {
			//background-color: fade(green, 50);
			left: initial;
			padding: 0 0.25em;
			line-height: 1em;
			right: 0em;
			bottom: -0.1em;
			//bottom: -2.4em;
			z-index: 2;
			//transform: scale(1.15);

			@media @mq[md] {
				bottom: -0.6em;
				padding: 0 0.5em;
				font-size: 0.5em;
			}
		}
		.vueperSlides__bullet {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
