<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvHomeCard></OvHomeCard>

	2020-11-23	init

-->

<template>
	<div class="OvHomeCard contentModule shadow shadow--default" :is=" hasLink ? 'a' : 'div'" :href="linkHref" :class="elmClasses">
		<div class="OvHomeCard__inner contentModule__inner">
			<div class="OvHomeCard__header">
				<MhImage class="OvHomeCard__image" :imageObject="image"></MhImage>
			</div>
			<div class="OvHomeCard__body">
				<h5 class="OvHomeCard__overTitle lineCrop font font--sizeMini" v-html="overTitle"></h5>
				<h3 class="OvHomeCard__title lineCrop breakWord font font--sizeDefault">
					<OvLabel2 class="font font--sizeSmall"
						:verticalAlign="'text-top'"
						:text="label"
						v-if="label !== '–'"
					></OvLabel2>
					<span class="font font--bold breakWord richText richText--noHyphens font--sizeDefault" v-html="nl2br( title )"></span>
				</h3>
				<div class="OvHomeCard__text breakWord richText lineCrop font font--sizeSmall" v-html="text"></div>
			</div>
			<!--
				<pre name="hasLink">{{hasLink}}</pre>
				<pre name="link">{{link}}</pre>
				<OvSpacer></OvSpacer>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import nl2br from '@/helper/nl2br.js'

	export default {
		name: 'OvHomeCard',
		components: {
			MhImage,
			OvLabel2,
			OvSpacer,
		},
		mixins: [],
		props: {
			image: {
				type: [Object, Boolean],
				default: false,
				required: false,
			},
			overTitle: {
				type: [String],
				default: '',
				required: false,
			},
			label: {
				type: [String],
				default: '',
				required: false,
			},
			title: {
				type: [String],
				default: '',
				required: false,
			},
			text: {
				type: [String],
				default: '',
				required: false,
			},
			link: {
				type: [Object],
				default: undefined,
				required: false,
			},
			/*
			*/
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.hasLink ) classes.push( this.$options.name + '--hasLink' )

				return classes
			},
			hasLink(){
				let hasLink = false

				if( this._.get( this.link, 'type' ) === 'postLink' && this._.get( this.link, 'postLink' ) ) hasLink = true
				if( this._.get( this.link, 'type' ) === 'customUrl' && this._.get( this.link, 'customUrl' ) ) hasLink = true

				return hasLink
			},
			linkHref(){
				let linkHref = ''

				if( this._.get( this.link, 'type' ) === 'postLink' )  linkHref = this._.get( this.link, 'postLink' )
				if( this._.get( this.link, 'type' ) === 'customUrl' ) linkHref = this._.get( this.link, 'customUrl' )

				return this.hasLink ? linkHref : ''
			},
		},
		methods: {
			nl2br( str, is_xhtml ){
				return nl2br( str, is_xhtml )
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvHomeCard { // debug
		//background-color: fade( red, 20 );
		//outline: 1px solid fade( red, 35 );
	}
	.OvHomeCard { // layout
		position: relative;
	}
	.OvHomeCard { // styling
		color: inherit;
		border-top: 3px solid currentColor;
		background-color: fade( white, 50 );
		transition: all 0.2s ease;

		&--hasLink:hover {
			background-color: fade( white, 100 );
			transform: translateY( -5px );
		}

		&__body { padding: 10px; border-top: 1px solid var(--color-primary-backgroundLine); }
		&__overTitle { padding: 0.25em 0 0.75em 0; }
		&__title .OvLabel2 { margin-right: 0.5em; transform: translateY( 22% ); }
		&__title { padding-bottom: 1em; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
