<template>
	<MhRouterView class="OvHomeView view background2 background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<!-- Aktuelle Hauptausstellungen und Veranstaltungen -->
			<div class="grid grid--exhibitionTeaserRow hSpace hSpace--app maxWidth maxWidth--app">
				<div>
					<div class="font font--bold font--sizeMini vSpace vSpace--miniTitle">
						{{$t('title.currentAndUpcomingExhibitions')}}
					</div>
					<!--
					<OvExhibitionTeaser
						class="hover hover--mainExhibtionTeaser shadow shadow--default"
						:class="['xl', 'lg', 'dt'].includes( $mq ) ? 'font font--sizeBig' : 'font font--sizeMedium'"
						:exhibitions="app.runningMainExhibitions"
					></OvExhibitionTeaser>
					-->
					<OvExhibitionTeaser
						class="hover hover--mainExhibtionTeaser shadow shadow--default"
						:class="['xl', 'lg', 'dt'].includes( $mq ) ? 'font font--sizeBig' : 'font font--sizeMedium'"
						:exhibitions="sliderExhibitions"
					></OvExhibitionTeaser>
					<OvSpacer type="afterExhibitionTeaser"></OvSpacer>
				</div>

				<div>
					<div class="font font--bold font--sizeMini vSpace vSpace--miniTitle">
						{{$t('title.events')}}
					</div>
					<OvEventsList
						:layout="'widget'"
						:limit="5"
						:exposeIds="_.map( app.runningMainExhibitions, 'id' )"
					></OvEventsList>
				</div>
			</div>

			<OvSpacer
				:type="'default'"
				:rows="4"
			></OvSpacer>

			<!-- Kacheln -->
			<div class="grid grid--homeCards hSpace hSpace--app maxWidth maxWidth--app">
				<OvHomeCard
					v-for="(card, index) in cards" :key="'c'+index"
					:overTitle="card.overTitle"
					:label="card.label"
					:title="card.title"
					:image="card.image"
					:text="card.text"
					:link="card.link"
				></OvHomeCard>
			</div>

			<OvSpacer
				:type="'default'"
				:rows="['xl', 'lg', 'dt'].includes( $mq ) ? 4 : 8"
			></OvSpacer>

			<OvSpacer
				type="default"
				:rows="['xl', 'lg', 'dt'].includes( $mq ) ? 4 : 8"
			></OvSpacer>

			<!-- Blog -->
			<OvBlogPostsList
				:showHeader="true"
				:showFooter="true"
			></OvBlogPostsList>

		</div>
		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="sliderExhibitions">{{sliderExhibitions}}</pre>
		<pre data-name="cards__de">{{cards__de}}</pre>
		<pre data-name="cards__en">{{cards__en}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvEventsList from '@/components/OvEventsList.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import OvDate from '@/components/OvDate.vue'
	import OvHomeCard from '@/components/OvHomeCard.vue'

	import OvExhibitionTeaser from '@/components/OvExhibitionTeaser.vue'
	import OvHeadline from '@/components/OvHeadline.vue'
	import OvBlogPostsList from '@/components/OvBlogPostsList.vue'

	export default {
		name: 'OvHomeView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvEventsList,
			OvMainFooter,
			OvSpacer,
			OvLabel2,
			OvDate,
			OvHomeCard,
			OvExhibitionTeaser,
			OvHeadline,
			OvBlogPostsList,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				sliderExhibitions: null,
				cards__de: null,
				cards__en: null,
				isContentReady : false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				//immediate: true,
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
				//immediate : true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			cards(){
				const lang = this.$root.$i18n.locale
				let cards = lang === 'en' ? this.cards__en : this.cards__de

				return cards
			},
		},
		methods: {
			fetchHomePage( callback = ()=>{}, doLog = true ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/homepage',
					params : {},
					callback : (response) => {
						const result = response.data.result

						this.sliderExhibitions = this._.get( result, 'sliderExhibitions' )
						this.cards__de = this._.get( result, 'cards__de' )
						this.cards__en = this._.get( result, 'cards__en' )

						callback()
					},
				})
			}
		},
		mounted() {
			this.fetchHomePage(()=>{
				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvHomeView { // layout
	}
	.OvHomeView { // styling
		// TODO: remove this after OvHomeCard is final implemented
		&__title {}
		&__text {
			padding-bottom: 1em;
		}
		&__textsRowLeft {
			padding-top: 0.65em;
			@media @mq[dt] {
				padding-right: 1.5em;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
